import { collection, getDocs, where, query } from 'firebase/firestore';
import { createSlice } from '@reduxjs/toolkit'

import { firestore } from '../../config/firebase';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    cards: {
      data: [],
      error: null,
      isLoading: false
    }
  },
  reducers: {
    dashboardCardsStartLoading: state => {
      state.cards.isLoading = true;
    },
    dashboardCardsSuccess: (state, action) => {
      state.cards.data = action.payload
      state.cards.isLoading = false
      state.cards.error = null
    },
    dashboardCardsError: (state, action) => {
      state.cards.isLoading = false
      state.cards.error = action.payload
    },
  }
})

export default dashboardSlice.reducer

// Action creators are generated for each case reducer function
export const { dashboardCardsSuccess, dashboardCardsError, dashboardCardsStartLoading } = dashboardSlice.actions

export const fetchCards = (uid) => async dispatch => {
  dispatch(dashboardCardsStartLoading())
  try {
    const q = query(collection(firestore, "cards"), where("uid", "==", uid));

    //const cardsRef = firestore.collection("//").where("uid", "==", uid)
    let dataset = [];
    await getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          dataset.push({ id: doc.id, ...doc.data() })
        });
        dispatch(dashboardCardsSuccess(dataset))
      })
      .catch((error) => {
        dispatch(dashboardCardsError(error))
      });
  }
  catch (e) {
    dispatch(dashboardCardsError(e))
  }
}