import React, { useEffect } from 'react'
import { motion } from "framer-motion"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import FirebaseAuth from '../../components/FirebaseAuth'
import Page from '../../components/Page'
import Spinner from '../../components/Spinner'
import Header from '../../components/Header'
import BusinessCard from '../../components/BusinessCard'
import { useAuth } from "../../contexts/AuthContext"
import CardContainer from '../../components/CardContainer'
import { activateAccount } from './activateSlice'
import { fetchCard } from '../card/cardSlice'

export default function ActivateAccount() {
    const { currentUser, logout } = useAuth()
    const history = useHistory()
    const { id } = useParams();
    const { isLoading, error } = useSelector(state => state.activate);
    const { data } = useSelector(state => state.card);
    const dispatch = useDispatch()

    useEffect(()=>{
        if(error && error.code){
            toast(error.code)
        }
    },[error])

    useEffect(() => {
        if (data && data.is_active) {
            history.push(`/${id}`)
        }
    }, [data])

    async function onStepComplete() {
        await dispatch(activateAccount(data, currentUser))
        await dispatch(fetchCard(id));
        history.push(`/${id}/activate/photo`)
    }

    return (
        <CardContainer>
            {data &&
                <Page>
                    <div className="flex flex-col items-center flex-1">
                        <div className=" flex-1 -mt-48 md:-mt-44" >
                            <motion.div layoutId="solocard" transition={{ duration: 0.8, ease: "backInOut" }} >
                                <BusinessCard dataset={data} />
                            </motion.div>

                            <div className="my-4" >
                                <Header steps={true} />
                            </div>
                            {currentUser && currentUser.uid ?
                                <motion.div
                                    transition={{ duration: 0.5 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }}
                                    className="flex-1 text-center mt-12">
                                    <motion.h1 transition={{ duration: 0.5, delay: 0.5 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} className="text-lg uppercase font-semibold" >You are currently signed in</motion.h1>
                                    <motion.p transition={{ duration: 0.5, delay: 0.4 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} className="text-md text-content-mute" >Your card will be linked to this number</motion.p>
                                    <motion.p transition={{ duration: 0.5, delay: 0.3 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} className="text-2xl uppercase my-12 font-alt font-light" >{currentUser.phoneNumber}</motion.p>
                                    <motion.a transition={{ duration: 0.5, delay: 0.2 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} className="text-blue-600" onClick={logout} >Change Number</motion.a>
                                </motion.div>
                                :
                                <motion.div
                                    transition={{ duration: 0.5 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }}
                                    className="flex-1 text-center mt-12">
                                    <FirebaseAuth />
                                </motion.div>
                            }
                        </div>
                        {currentUser &&
                            <motion.div className="w-full max-w-lg fixed bottom-0 p-8"
                                transition={{ duration: 0.5, ease: "backInOut" }} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }}>
                                <a
                                    onClick={onStepComplete}
                                    className="cursor-pointer w-full inline-flex justify-center py-4 border border-transparent shadow hover:shadow-lg  text-sm font-light rounded-sm text-white uppercase bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 " >
                                    {(isLoading) ?
                                        <Spinner className="fill-current fill-white" size="small" /> :
                                        'CONTINUE'
                                    }
                                </a>
                            </motion.div>
                        }
                    </div>
                </Page>
            }
        </CardContainer>
    )
}
