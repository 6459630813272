import React from 'react'
import { motion } from "framer-motion"
import clsx from 'clsx'

export default function Page({ children,  background, paddingx = true, paddingy = true }) {

    return (
        <div className={clsx(["flex flex-col flex-1 max-w-lg w-full relative min-h-screen", paddingx && "px-8 ", paddingy && "py-8 "])}>
            {children}
            {background &&
                <motion.div transition={{ duration: 0.5, delay: 0.5 }} initial={{ opacity: .05 }} animate={{ opacity: .05 }} exit={{ opacity: 0 }} className="fixed bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/4 opacity-5" style={{ width: '840px', zIndex: -1 }}>
                    <svg className="fill-current text-content-mute mx-auto my-8 w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432.06 336"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M413.92,336a17.71,17.71,0,0,1-12.53-5.19L101.25,30.67a17.71,17.71,0,0,1,25-25L396.2,275.54V168.66a17.71,17.71,0,0,1,35.42,0V318.29A17.71,17.71,0,0,1,413.92,336Z" /><path d="M315.19,135.46a17.71,17.71,0,0,1-12.52-5.18l-100.05-100A17.71,17.71,0,0,1,215.15,0h199.2a17.71,17.71,0,0,1,12.58,30.17L327.77,130.22a17.7,17.7,0,0,1-12.54,5.24ZM257.9,35.41l57.24,57.25,56.73-57.25Z" /><path d="M314.63,336H115.42a17.71,17.71,0,0,1-12.58-30.17l99.17-100a17.66,17.66,0,0,1,12.53-5.24h0a17.69,17.69,0,0,1,12.52,5.18l100,100A17.71,17.71,0,0,1,314.63,336ZM157.9,300.58h114l-57.24-57.24Z" /><path d="M17.71,335.56A17.72,17.72,0,0,1,5.07,305.44L121.58,186.81H17.71A17.7,17.7,0,0,1,0,169.11V18.59A17.71,17.71,0,0,1,30.43,6.28L83.11,60.73A17.71,17.71,0,0,1,57.66,85.36l-22.25-23v89H163.79a17.71,17.71,0,0,1,12.64,30.11L30.34,330.26A17.66,17.66,0,0,1,17.71,335.56Z" /></g></g></svg>
                </motion.div>
            }
        </div>
    )
}
