import React, { } from 'react'
import { motion } from "framer-motion"
import { Link, useHistory, useParams } from "react-router-dom"
import { useSelector } from 'react-redux'


import Page from '../../components/Page'
import Header from '../../components/Header'
import Shortcuts from '../../components/Shortcuts'
import BusinessCard from '../../components/BusinessCard'
import InfoList from '../../components/InfoList'
import { useAuth } from "../../contexts/AuthContext"
import { useQuery } from '../../utils/hooks'
import CardContainer from '../../components/CardContainer'

function Card() {

  const { data } = useSelector(state => state.card);
  const { currentUser } = useAuth()
  const history = useHistory()
  const { id } = useParams();
  const query = useQuery();
  const preview = query.get("preview");


  function handleActivate() {
    history.push(`/${id}/activate/`)
  }

  function handleEdit() {
    history.push(`/${id}/edit/`)
  }


  return (
    <CardContainer>
      {data && <Page theme={data.type} background={data.is_active ? false : true}>
        {data.is_active ?
          <div className="flex flex-col items-center">

            <div className="mb-4 w-full -mt-4">
              <Header />
            </div>

            <motion.div layoutId="solocard" transition={{ duration: 0.8, ease: "backInOut" }}>
              <BusinessCard dataset={data} />
            </motion.div>

            <motion.div layoutId="shortcuts-container h-28" className="w-full" transition={{ duration: 0.8, ease: "backInOut", }} >
              <Shortcuts stagger="false" card={data} edit={true} />
            </motion.div>

            <motion.div className="w-full" layoutId="info-container" transition={{ duration: 0.8, ease: "backInOut", }} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <InfoList card={data} />
            </motion.div>

            {(preview !== "true" && currentUser && data.uid == currentUser.uid) &&
              <motion.div
                transition={{ duration: 0.5, ease: "backInOut" }} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }}
                className="w-full max-w-lg fixed bottom-0 p-8 grid grid-cols-2 gap-4"
                style={{ backgroundImage: "linear-gradient(rgba(229, 229, 229, 0), rgba(229, 229, 229, 1) 25%)" }}
              >
                <div>
                  <a
                    onClick={handleEdit}
                    className="cursor-pointer w-full inline-flex justify-center py-4 border border-transparent shadow hover:shadow-lg shadow-sm text-sm font-light rounded-sm text-white uppercase bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 " >
                    <i className="icon-settings mr-2 text-lg" />Edit
                  </a>
                </div>
                <Link to={`/${data.id}?preview=true`} target="_blank" className="w-full inline-flex justify-center py-4 border border-content shadow hover:shadow-lg text-sm font-light rounded-sm text-content uppercase focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-content " >
                  Preview
                </Link>

              </motion.div>
            }
          </div>
          :
          <div className="flex flex-col items-center justify-start flex-1">
            <motion.div className="flex flex-col items-center mb-4 mt-4" transition={{ duration: 0.5 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Link to="/">
                <svg className="fill-current text-content mx-auto mb-4 w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432.06 336"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M413.92,336a17.71,17.71,0,0,1-12.53-5.19L101.25,30.67a17.71,17.71,0,0,1,25-25L396.2,275.54V168.66a17.71,17.71,0,0,1,35.42,0V318.29A17.71,17.71,0,0,1,413.92,336Z" /><path d="M315.19,135.46a17.71,17.71,0,0,1-12.52-5.18l-100.05-100A17.71,17.71,0,0,1,215.15,0h199.2a17.71,17.71,0,0,1,12.58,30.17L327.77,130.22a17.7,17.7,0,0,1-12.54,5.24ZM257.9,35.41l57.24,57.25,56.73-57.25Z" /><path d="M314.63,336H115.42a17.71,17.71,0,0,1-12.58-30.17l99.17-100a17.66,17.66,0,0,1,12.53-5.24h0a17.69,17.69,0,0,1,12.52,5.18l100,100A17.71,17.71,0,0,1,314.63,336ZM157.9,300.58h114l-57.24-57.24Z" /><path d="M17.71,335.56A17.72,17.72,0,0,1,5.07,305.44L121.58,186.81H17.71A17.7,17.7,0,0,1,0,169.11V18.59A17.71,17.71,0,0,1,30.43,6.28L83.11,60.73A17.71,17.71,0,0,1,57.66,85.36l-22.25-23v89H163.79a17.71,17.71,0,0,1,12.64,30.11L30.34,330.26A17.66,17.66,0,0,1,17.71,335.56Z" /></g></g></svg>
              </Link>
              <span className="text-content text-content-mute text-md tracking-widest font-light uppercase my-2" >WELCOME</span>
              <h1 className="text-3xl uppercase font-light" >{data.first_name}</h1>
            </motion.div>
            <div className="my-8" >
              <motion.div layoutId="solocard" transition={{ duration: 0.8, ease: "backInOut" }}>
                <BusinessCard dataset={data} onClick={handleActivate} />
              </motion.div>
              <motion.span transition={{ duration: 0.5, delay: 0.3 }} initial={{ y: -5, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 5, opacity: 0 }} className="text-sm text-content-mute tracking-widest	uppercase w-full text-center block my-4">
                {data.type} EDITION
              </motion.span>
            </div>

            <motion.div className="w-full max-w-lg fixed bottom-0 p-8"
              transition={{ duration: 0.5, ease: "backInOut" }} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }}
            >
              <a
                onClick={handleActivate}
                className="w-full inline-flex justify-center py-4 border border-transparent shadow-sm text-sm font-light rounded-sm text-white uppercase bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer">
                Activate My Card
              </a>
            </motion.div>
          </div>
        }
      </Page>
      }
    </CardContainer>
  )

}

export default Card