import React, { useEffect, useState } from 'react'
import { RecaptchaVerifier, PhoneAuthProvider } from 'firebase/auth'
import { useFormik } from 'formik';
import { motion } from "framer-motion"
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { isValidPhoneNumber } from 'react-phone-number-input'
import clsx from 'clsx';
import useGeoLocation from "react-ipgeolocation";

import { auth } from '../config/firebase'
import PhoneInputField from '../components/PhoneInputField'
import Spinner from '../components/Spinner';
import { useAuth } from "../contexts/AuthContext"

const FirebaseAuth = () => {

  const [renderAuth, setRenderAuth] = useState(true)
  const [verificationCode, setVerificationCode] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [loc, setLoc] = useState('US')
  const geoLoc = useGeoLocation();
  const [seconds, setSeconds] = React.useState(0);
  const { login, requestOTP } = useAuth()

  //const provider = new PhoneAuthProvider(auth)

  
  const phoneForm = useFormik({
    initialValues: {
      phone: '',
    },
    onSubmit: async (values) => {
      
      setIsLoading(true)
      if (isValidPhoneNumber(values.phone)) {
        const appVerifier = await window.recaptchaVerifier;
        requestOTP(auth, values.phone, appVerifier)
          .then((confirmationResult) => {
            setVerificationCode(confirmationResult.verificationId);
            setIsLoading(false)
            setSeconds(60)
          }).catch((error) => {
            toast(error.message);
            setIsLoading(false)
          });
      }
      else {
        toast("Please enter a valid phone number");
        setIsLoading(false)
      }

    },
  });

  const otpForm = useFormik({
    initialValues: {
      otp: '',
    },
    //validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values.otp.length == 6) {
        setIsLoading(true)
        
        var credential = await PhoneAuthProvider.credential(verificationCode, values.otp.toString());
        login(credential)
          .then((s) => {
            setIsLoading(false)
          }).catch((error) => {
            toast(error.message);
            setIsLoading(false)
          });
      }
      else {
        toast('Please enter the 6 digits code we sent to your phone number');
        setIsLoading(false);
      }
    }
  });

  function handleReset(){
    setVerificationCode(null)
  }


  function handleCTA() {
    if (!isLoading) {
      if (verificationCode) {
        otpForm.handleSubmit()
      }
      else {
        phoneForm.handleSubmit()
      }
    }

  }

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds])

  useEffect(async () => {
    
    
    if (typeof window !== 'undefined') {
      setRenderAuth(true)

      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
        },
        'expired-callback': () => {
        }
      }, auth);

    }

  }, [])

  useEffect(() => {
    if (geoLoc && geoLoc.country && geoLoc.country !== loc) {
      setLoc(geoLoc.country);
    }
  }, [geoLoc])

  return (
    <div>

      {renderAuth ? (
        <div className="mx-auto">
          {
            !verificationCode ?
              <>
                <motion.h1 transition={{ duration: 0.5, delay: 0.45 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} className="text-lg uppercase font-semibold mb-2" >Sign In</motion.h1>
                <motion.p transition={{ duration: 0.5, delay: 0.4 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} className="text-md text-content-mute px-4" >Let’s set a mobile number that you’ll use to sign-in to edit your SOLO cards</motion.p>
                <div className="mt-12">

                  <form onSubmit={phoneForm.handleSubmit}>
                    <motion.div
                      className="relative w-full mb-3"
                      transition={{ duration: 0.5, delay: 0.5 }} initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }}
                    >

                      <PhoneInputField
                        name="phone"
                        international
                        countrySelectProps={{ unicodeFlags: true }}
                        countryCallingCodeEditable={false}
                        defaultCountry={loc}
                        formik={phoneForm}
                        onChange={e => phoneForm.setFieldValue("phone", e)}
                      />
                    </motion.div>
                  </form>
                </div>
              </>
              :
              <motion.div
                transition={{ duration: 0.5, delay: 0 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }}
              >
                <motion.h1 transition={{ duration: 0.5, delay: 0.45 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} className="text-lg uppercase font-semibold" >Enter Security Code</motion.h1>
                <motion.p transition={{ duration: 0.5, delay: 0.4 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} className="text-md text-content-mute" >

                  <span className="text-xs uppercase my-4 block">Enter 6-digit code we sent to your mobile number </span>
                  <span className="text-lg font-medium uppercase my-4 font-alt font-light block">{phoneForm.values.phone}</span>
                  <span className="text-lg font-medium uppercase my-4 font-alt font-light block"><button className="text-blue-600 text-sm" onClick={handleReset}>Change Number</button></span>

                </motion.p>


                <motion.div className=""

                  transition={{ duration: 0.5, delay: 0.3 }} initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }}>

                  <form onSubmit={otpForm.handleSubmit}>
                    <motion.div className="relative w-full my-6"
                      transition={{ duration: 0.5, delay: 0.3 }} initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }}
                    >
                      <OtpInput
                        name="otp"
                        onChange={(e) => { otpForm.setFieldValue('otp', e) }}
                        numInputs={6}
                        formik={otpForm}
                        value={otpForm.values.otp}
                        isInputNum={true}
                        containerStyle="w-full justify-evenly"
                        inputStyle="otp-input rounded-md  text-xl  border-gray-300 mt-1 focus:outline-none focus:ring-1 h-11  min-w-full focus:ring-blue-600 focus:border-transparent font-alt"
                      />
                    </motion.div>
                  </form>
                </motion.div>

                <motion.div className="relative w-full text-sm font-bold pt-2" transition={{ duration: 0.5, delay: 0.4 }} initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }}>
                  <button type="submit" disabled={seconds > 0} onClick={() => { phoneForm.handleSubmit() }} className={clsx(["text-blue-600", (seconds > 0) && "opacity-50"])}>Resend Code</button>
                  {seconds > 0 && <span className="font-alt text-xs text-content block mt-2"> {seconds} seconds </span>}
                </motion.div>
              </motion.div>
          }
        </div>
      ) : null}
      <div id="recaptcha-container"></div>
      <motion.div
        transition={{ duration: 0.5, ease: "backInOut" }} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }}
        className="w-full fixed bottom-0 left-0">
        <div className="max-w-lg mx-auto  p-8 ">
          <a
            onClick={handleCTA}
            className="cursor-pointer w-full inline-flex justify-center py-4 border border-transparent shadow hover:shadow-lg  text-sm font-light rounded-sm text-white uppercase bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 " >
            {isLoading ? <Spinner className="fill-current fill-white" size="small" /> : verificationCode ? 'Confirm' : 'Sign In'}
          </a>
        </div>
      </motion.div>

    </div>
  )
}

export default FirebaseAuth
