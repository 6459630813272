import { doc, updateDoc } from "firebase/firestore";
import { createSlice } from '@reduxjs/toolkit'
import { firestore } from '../../config/firebase';


export const activateSlice = createSlice({
  name: 'activate',
  initialState: {
    error: null,
    isLoading: false
  },
  reducers: {
    activateStartLoading: state => {
      state.isLoading = true;
    },
    activateSuccess: state => {
      state.isLoading = false
      state.error = null
    },
    activateError: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  }
})

export default activateSlice.reducer

// Action creators are generated for each case reducer function
export const { activateError, activateSuccess, activateStartLoading } = activateSlice.actions

export const activateAccount = (card, account) => async dispatch => {
  dispatch(activateStartLoading())
  try {
    let number = account.phoneNumber;
    let itm = {
      label: 'Mobile',
      value: account.phoneNumber.replace('+', ''),
      icon: 'icon-phone',
      link: `tel:${account.phoneNumber}`,
      oid: "c1",
      iid: "bi1",
      prefix: "+"
    }

    var cardRef = doc(firestore, 'cards', card.id)
    return updateDoc(cardRef, {
      phone: number.replace('+', ''),
      uid: account.uid,
      dataset: [itm]
    })
      .then(() => {
        dispatch(activateSuccess())
      })
      .catch((error) => {
        dispatch(activateError(error))
      });

  }
  catch (e) {
    dispatch(activateError(e))
  }
}

export const activatePhoto = (card, uri) => async dispatch => {
  dispatch(activateStartLoading())
  try {

    var cardRef = doc(firestore, 'cards', card.id)

    return updateDoc(cardRef,{
      'avatar_url': uri,
    })
      .then(() => {
        dispatch(activateSuccess())
      })
      .catch((error) => {
        dispatch(activateError(error))
      });



  }
  catch (e) {
    dispatch(activateError(e))
  }
}

export const activateInformation = (card, dataset) => async dispatch => {
  dispatch(activateStartLoading())
  try {
    var cardRef = doc(firestore, 'cards', card.id)
    //var cardRef = firestore.collection("cards").doc(card.id);
    return updateDoc(cardRef,{
      dataset: dataset
    })
      .then(() => {
        dispatch(activateSuccess())
      })
      .catch((error) => {
        dispatch(activateError(error))
      });


  }
  catch (e) {
    dispatch(activateError(e))
  }
}

export const activateReview = (card) => async dispatch => {
  dispatch(activateStartLoading())
  try {
    var cardRef = doc(firestore, 'cards', card.id)
    return updateDoc(cardRef,{
      is_active: true
    })
      .then(() => {
        dispatch(activateSuccess())
      })
      .catch((error) => {
        dispatch(activateError(error))
      });


  }
  catch (e) {
    dispatch(activateError(e))
  }
}