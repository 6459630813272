import React, { useContext, useState, useEffect } from "react"
import { signInWithPhoneNumber , signInWithCredential, signOut , onAuthStateChanged} from 'firebase/auth'
import {auth} from '../config/firebase'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function requestOTP(auth, phone,recaptcha) {
    return signInWithPhoneNumber(auth, phone,recaptcha)
  }

  function login(creds) {
    return signInWithCredential(auth, creds)
  }

  function logout() {
    return signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    logout,
    requestOTP
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}