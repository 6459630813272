import React from 'react'
import { motion } from "framer-motion"
import Obfuscate from 'react-obfuscate';

import generateVCard from '../utils/generateVCard';
import IconButton from './IconButton';

export default function Shortcuts({ stagger = false, card, edit = false }) {
    const numbers = card.dataset.filter((o) => {
        return o.iid == 'bi1';
    });

    return (
        <div className="flex-1 text-center flex justify-around my-6">

            {numbers.length > 0 &&
                <motion.div
                    transition={{ duration: 0.3, delay: 0.1 }} initial={{ opacity: 0, y: 5 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -5 }}

                >
                    <Obfuscate href={numbers[0].link} >
                        <IconButton label="Call" icon="phone" />
                    </Obfuscate>
                </motion.div>

            }

            {numbers.length > 0 &&
                <motion.div
                    transition={{ duration: 0.3, delay: 0.2 }} initial={{ opacity: 0, y: 5 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -5 }}

                >
                    <Obfuscate href={`sms:+${numbers[0].value}`} >
                        <IconButton label="Message" icon="message-square" />
                    </Obfuscate>
                </motion.div>
            }


            <motion.div
                onClick={() => { generateVCard(card) }}
                transition={{ duration: 0.3, delay: 0.3 }} initial={{ opacity: 0, y: 5 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -5 }}
            >
                <IconButton label="Save Contact" icon="book" color="blue-600" variant="contained" />
            </motion.div>




        </div>
    )
}
