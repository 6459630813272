import React from 'react'
import clsx from 'clsx'

export default function TextField({ start, end, label, onChange, onKeyDown, onKeyUp, onFocus, onBlur, ref, name, value, formik, type = "text" }) {
    return (
        <div className="relative h-12 input-component mt-6 border  rounded-sm flex  ">
            {start && <span className="h-full flex items-center justify-center pl-3  text-content-mute text-sm">{start}</span>}
            <input
                formik={formik}
                ref={ref}
                type={type}
                name={name}
                className={clsx(["h-full w-full flex-1 px-2 focus:outline-none shadow-none font-alt", start && "pl-0"])}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                value={value}

            />
            {end && <span className="h-full flex items-center justify-center pr-3 text-content-mute">{end}</span>}
            <label className=" absolute left-2 -top-2 transition-all bg-white px-1 text-blue-500 uppercase text-xs md:tx-sm">{label}</label>
        </div>
    )
}
