import React from 'react'
import { motion } from "framer-motion"
import {  useHistory } from "react-router-dom"

import { useAuth } from "../contexts/AuthContext"


const Header = ({steps}) => {

  const { currentUser, logout} = useAuth()
  const history = useHistory()

  return (
    <div className="flex w-full justify-between content-center items-center">
      {
        steps ?
          <motion.div transition={{ duration: 0.5 }} initial={{ x: 10, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -10, opacity: 0 }}>
            <a onClick={() => history.goBack()} className="py-3 text-xl flex items-center cursor-pointer">
              <i className="icon-arrow-left  text-content-mute" />
            </a>
          </motion.div>
          :
          <motion.div transition={{ duration: 0.5 }} initial={{ x: 10, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -10, opacity: 0 }}>
            <a onClick={() => history.push('/')} className="py-3 text-2xl flex items-center cursor-pointer">
              <i className="icon-solo text-content" />
            </a>
          </motion.div>
      }


      <motion.div transition={{ duration: 0.5 }} initial={{ x: -10, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: 10, opacity: 0 }}>
        {currentUser ?
          <a onClick={logout} className="py-3 text-xl flex items-center  cursor-pointer">
            <span className="text-xs uppercase font-semibold text-content-mute pr-1 text-2xs cursor-pointer" >Sign Out</span><i className="icon-log-out text-content-mute text-xs" />
          </a>
          :
          <a onClick={() => history.push('/login')}  className="py-3 text-xl flex items-center  ">
            {/* <span className="text-xs uppercase font-semibold text-blue-600 pr-2" >Get your card</span> */}
            <span className="text-xs uppercase font-semibold text-content-mute pr-1 text-2xs cursor-pointer" >Sign In</span><i className="icon-log-in text-content-mute text-xs" />
          </a>
        }
      </motion.div>
    </div>
  )
}

export default Header
