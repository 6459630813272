import React from 'react';
import clsx from 'clsx'

export default function BusinessCard({ dataset, onClick }) {

    function getAvatarWidth() {
        if (dataset.photo_aspect_ratio == 'v') { return "h-10 md:h-14" }
        if (dataset.photo_aspect_ratio == 'h') { return "h-8 md:h-10" }
        if (dataset.photo_aspect_ratio == 's') { return "h-12 md:h-16" }
    }
    return (
        <div className={clsx(["shadow-xl relative overflow-hidden rounded-lg", onClick && "cursor-pointer"])} onClick={onClick}>

            {dataset.photo_url &&
                <div className="h-1/2 w-full pt-7 absolute top-0 left-8 flex items-center">
                    <div className="w-full">
                        <img src={dataset.photo_url} className={clsx([getAvatarWidth(), (dataset.type == 'carbon' || dataset.type == 'black' || dataset.type == 'space-grey')? 'mix-blend-soft-light':'mix-blend-overlay'])} />
                    </div>
                </div>
            }

            <div className={clsx(["absolute pt-4 bottom-0 left-8 uppercase h-1/2 ", (dataset.type == 'carbon' || dataset.type == 'black' || dataset.type == 'space-grey')? 'text-content-invert mix-blend-soft-light' : 'mix-blend-overlay'])}>
                <h1 className={clsx(["font-serif text-sm md:text-lg leading-tight md:leading-tight", (dataset.type == 'carbon' || dataset.type == 'black' || dataset.type == 'space-grey') ? "text-white" : "text-black"])}>{dataset.first_name} {dataset.last_name}{dataset.suffix && <span className='text-xs capitalize'>, {dataset.suffix}</span>}</h1>
                {dataset.title && <h2 className={clsx(["tracking-tight md:tracking-wide text-2xs  ", (dataset.type == 'carbon' || dataset.type == 'black' || dataset.type == 'space-grey') ? "text-white" : "text-black"])}>{dataset.title}</h2>}
            </div>
            <img
                src={`/img/solo-${dataset.type}.jpg`}
                alt="SOLO CARD"
                className="max-width-full"
                width="800"
                height="500"
                loading="eager"
            />
        </div>
    )
}
