import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import {  useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import Page from '../../components/Page'
import Header from '../../components/Header'
import BusinessCard from '../../components/BusinessCard'
import CardContainer from '../../components/CardContainer'
import { activatePhoto } from './activateSlice'
import { fetchCard } from '../card/cardSlice'
import PhotoUpload from '../../components/PhotoUpload'

export default function ActivatePhoto() {

    const history = useHistory()
    const { id } = useParams();
    const { isLoading, error } = useSelector(state => state.activate);
    const { data } = useSelector(state => state.card);
    const dispatch = useDispatch()

    useEffect(()=>{
        if(error && error.code){
            toast(error.code)
        }
    },[error])

    useEffect(() => {
        if (data && data.is_active) {
            history.push(`/${id}`)
        }
    }, [data])
    
    async function onStepComplete(uri) {
        if (uri === false) {
            await dispatch(activatePhoto(data, null))
        }
        else if (uri) {
            await dispatch(activatePhoto(data, uri))
        }
        await dispatch(fetchCard(id));
        history.push(`/${id}/activate/information`)
    }

    return (
        <CardContainer>
            {data &&
                <Page >
                    <div className="flex flex-col items-center flex-1">

                        <div className="flex-1  -mt-48 md:-mt-44 " >
                            <motion.div layoutId="solocard" transition={{ duration: 0.8, ease: "backInOut" }}>
                                <BusinessCard dataset={data} />
                            </motion.div>
                            <div className="my-4" >
                                <Header steps={true} />
                            </div>
                            <div className="flex-1 text-center mt-12">

                                <PhotoUpload card={data} onSuccess={onStepComplete} proccessing={isLoading} />

                            </div>

                        </div>


                    </div>


                </Page>
            }
        </CardContainer>
    )
}
