import React, { useEffect } from 'react'
import { motion } from "framer-motion"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import Page from '../../components/Page'
import Spinner from '../../components/Spinner'
import BusinessCard from '../../components/BusinessCard'
import InfoList from '../../components/InfoList'
import CardContainer from '../../components/CardContainer'
import { activateReview } from './activateSlice'
import { fetchCard } from '../card/cardSlice'
import Shortcuts from '../../components/Shortcuts'

export default function ActivateReview() {

    const history = useHistory()
    const { id } = useParams();
    const { isLoading, error} = useSelector(state => state.activate);
    const { data } = useSelector(state => state.card);
    const dispatch = useDispatch()

    useEffect(()=>{
        if(error && error.code){
            toast(error.code)
        }
    },[error])

    useEffect(() => {
        if (data && data.is_active) {
            history.push(`/${id}`)
        }
    }, [data])
    
    async function onStepComplete() {
        await dispatch(activateReview(data))
        await dispatch(fetchCard(id));
        history.push(`/${id}/activate/success`)
    }

    return (
        <CardContainer>
            {data &&
                <Page >
                    <div className="flex flex-col items-center flex-1">
                        <div className="flex-1" >
                            <motion.div layoutId="solocard" transition={{ duration: 0.8, ease: "backInOut" }}>
                                <BusinessCard dataset={data} />
                            </motion.div>

                            <motion.div
                                layoutId="shortcuts-container h-28" className="w-full" transition={{ duration: 0.8, ease: "backInOut" }} initial={{ y: -40, opacity: 0 }} animate={{ y: 0, opacity: 1 }}
                            >
                                <Shortcuts stagger="false" card={data} />
                            </motion.div>

                            <motion.div className="w-full " layoutId="info-container" transition={{ duration: 0.8, ease: "backInOut" }}>
                                <InfoList card={data} />
                            </motion.div>

                        </div>
                        <div
                            className="w-full max-w-lg fixed bottom-0 p-8 flex flex-row grid grid-cols-2 gap-4"
                            style={{ backgroundImage: "linear-gradient(rgba(229, 229, 229, 0), rgba(229, 229, 229, 1) 25%)" }}
                        >
                            <motion.div
                                transition={{ duration: 0.5, delay: 0.1, ease: "backInOut" }} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }}
                                className="">
                                <a
                                    onClick={() => { history.goBack() }}
                                    className="w-full inline-flex justify-center py-4 border border-content shadow hover:shadow-lg text-sm font-light rounded-sm text-content uppercase focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-content " >Back</a>
                            </motion.div>

                            <motion.div
                                transition={{ duration: 0.5, ease: "backInOut" }} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }}
                                className=""
                            >

                                <a
                                    onClick={onStepComplete}
                                    className="cursor-pointer w-full inline-flex justify-center py-4 border border-transparent shadow hover:shadow-lg text-sm font-light rounded-sm text-white uppercase bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 " >
                                    {isLoading ? <Spinner className="fill-current fill-white" size="small" /> : "Finish"}
                                </a>

                            </motion.div>
                        </div>
                    </div>
                </Page>

            }
        </CardContainer>
    )
}
