
import FileSaver from "file-saver";
import { inputFieldOptions } from '../utils/getInputFieldOptions';

const generateVCard = (card) => {
  var startIndex = 1;
  var datastring = '';
  var cc = '';
  var photo = ''
  var organization = null;

  const sendFile = () => {
    try {
      var file = new Blob(
        [cc.toString()],
        { type: "text/vcard;charset=utf-8" }
      );

      let a = document.createElement("a");
      a.download = `${card.first_name}-${card.last_name?card.last_name:''}.vcf`;
      a.href = URL.createObjectURL(file);
      var reader = new FileReader();
      if (navigator.userAgent.match("CriOS")) {
        reader.onloadend = e => {
          window.open(reader.result);
        };
        reader.readAsDataURL(file);
      }
      else if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
        reader.onload = e => {
          window.location.href = reader.result;
        };
        reader.readAsDataURL(file);
      }
      else {
        FileSaver.saveAs(
          file,
          `${card.first_name}-${card.last_name?card.last_name:''}.vcf`,
          true
        );
      }
    }
    catch (e) {
    }
  }

  card.dataset.forEach((itm, index) => {
    let opt = inputFieldOptions.find((o) => {
      return o.id == itm.oid
    })

    let selection = opt.inputs.find((s) => {
      return s.id == itm.iid
    })

    if (selection.id == 'bi3') {
      organization = itm.value
    }

    if (selection.xcode) {
      var str;

      if (selection.xcode == 'TEL') {
        str = `item${startIndex}.${selection.xcode}:${selection.prefix}${itm.value}\nitem${startIndex}.X-ABLabel:${itm.label}\n`
      }
      else if (selection.xcode == 'URL') {
        str = `item${startIndex}.${selection.xcode}:${selection.code}${itm.value}\nitem${startIndex}.X-ABLabel:${itm.label}\n`
        //return
      }
      else if (selection.xcode == 'EMAIL') {
        str = `item${startIndex}.${selection.xcode}:${itm.value}\nitem${startIndex}.X-ABLabel:${itm.label}\n`
      }
      else if (selection.xcode == 'IMPP;X-SERVICE-TYPE') {
        str = `item${startIndex}.${selection.xcode}=${selection.title}:${selection.prefix}${itm.value}\nitem${startIndex}.X-ABLabel:${itm.title}\n`
        //return
      }
      else {
        //str = `item${startIndex}.${selection.xcode}:${itm.value}\nitem${startIndex}.X-ABLabel:${itm.label}\n`
        return
      }
      startIndex++;
      datastring = datastring + str;
    }
  })


  if (card.avatar_url) {
    photo = `PHOTO;TYPE=PNG;ENCODING=BASE64:${card.avatar_url.replace('data:image/png;base64,', '')}`
  }

  cc = `BEGIN:VCARD
VERSION:3.0
N:${card.last_name?card.last_name:''};${card.first_name?card.first_name:''};;${card.prefix ? card.prefix : ''};${card.suffix ? card.suffix : ''};
FN:${card.prefix ? card.prefix + ' ' : ''}${card.first_name?card.first_name:''} ${card.last_name?card.last_name:''} ${card.suffix ? card.suffix : ''}
${organization? "ORG:" + organization : card.organization ? "ORG:" + card.organization : ''}
${card.title ? "TITLE:" + card.title : ''}
item0.URL:https://my.solo-card.com/${card.id}
item0.X-ABLabel:SOLO CARD LINK
${datastring}
${photo}
END:VCARD`

  sendFile()

}

export default generateVCard;