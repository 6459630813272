export const inputFieldOptions = [
    {
        id: 'c1', title: "Profile Information", icon: "icon-user", inputs: [
            { id: 'bi1', title: "Phone", prefix: "+", icon: "icon-phone", label: "Phone Number", code: "tel:+", type: "tel" , xcode: "TEL"},
            { id: 'bi2', title: "Email", prefix: "", icon: "icon-mail", label: "email address", code: "mailto:", type: "email", xcode: "EMAIL" },
            { id: 'bi3', title: "Company", prefix: "", icon: "icon-briefcase", label: "company name", code: "", type: "text" },
            { id: 'bi6', title: "Website", prefix: "https://", icon: "icon-globe", label: "url", code: "https://", type: "url", xcode: "URL" },
            { id: 'bi7', title: "Link", prefix: "https://", icon: "icon-link", label: "url", code: "https://", type: "url", xcode: "URL" },
        ]
    },
    {
        id: 'c2', title: "Social Media", icon: "icon-share-2", inputs: [
            { id: 'sm1', title: "Facebook", prefix: "facebook.com/", icon: "icon-facebook", label: "Facebook Profile", code: "https://www.facebook.com/", type: "url", xcode: "URL" },
            { id: 'sm2', title: "Instagram", prefix: "@", icon: "icon-instagram", label: "Instagram Profile", code: "https://www.instagram.com/", type: "text", xcode: "URL" },
            { id: 'sm3', title: "Linkedin", prefix: "linkedin.com/in/", icon: "icon-linkedin", label: "LinkedIn Profile", code: "https://www.linkedin.com/in/", type: "url", xcode: "URL" },
            { id: 'sm4', title: "Twitter", prefix: "@", icon: "icon-twitter", label: "Twitter Profile", code: "https://twitter.com/", type: "text", xcode: "URL" },
            { id: 'sm5', title: "Snapchat", prefix: "", icon: "icon-snapchat", label: "Snapchat ID", code: "https://www.snapchat.com/add/", type: "text", xcode: "URL" },
            { id: 'sm6', title: "Tiktok", prefix: "@", icon: "icon-tiktok", label: "Tiktok Profile", code: "https://www.tiktok.com/@", type: "text", xcode: "URL" },
            { id: 'sm7', title: "Youtube", prefix: "youtube.com/", icon: "icon-youtube", label: "Youtube Channel or Profile", code: "https://www.youtube.com/", type: "url", xcode: "URL" },
            { id: 'sm8', title: "Vimeo", prefix: "vimeo.com/", icon: "icon-vimeo", label: "Vimeo Profile ", code: "https://vimeo.com/", type: "url", xcode: "URL" },
            // { id: 'sm9', title: "Clubhouse", prefix: "clubhouse://" , icon: "icon-info"},
        ]
    },
    {
        id: 'c3', title: "Instant Messaging", icon: "icon-send", inputs: [
            { id: 'ms2', title: "Whatsapp", prefix: "+", icon: "icon-whatsapp", label: "Phone", code: "https://wa.me/", type: "tel", xcode: "URL" },
            { id: 'ms3', title: "Telegram", prefix: "@", icon: "icon-telegram", label: "Telegram ID", code: "https://t.me/", type: "text", xcode: "URL" },
            { id: 'ms1', title: "Messenger", prefix: "", icon: "icon-messenger", label: "Facebook Messenger ID", code: "https://m.me/", type: "text", xcode: "URL" },
            { id: 'ms4', title: "Skype", prefix: "", icon: "icon-skype", label: "Skype ID", code: "skype:", type: "text", xcode: "IMPP;X-SERVICE-TYPE" },
            { id: 'ms5', title: "Line", prefix: "@", icon: "icon-line", label: "Line ID", code: "http://line.me/ti/p/@", type: "text", xcode: "URL" },
            { id: 'ms8', title: "Viber", prefix: "+", icon: "icon-viber", label: "Phone", code: "viber://chat?number=+", type: "tel", xcode: "IMPP;X-SERVICE-TYPE" },
            { id: 'ms9', title: "WeChat", prefix: "", icon: "icon-wechat", label: "WeChat ID", code: "weixin://dl/chat?", type: "text", xcode: "IMPP;X-SERVICE-TYPE" },
            // { id: 'ms6', title: "Kik", prefix: "", icon: "icon-kik", label: "profile" },
            // { id: 'ms7', title: "Handgouts", prefix: "", icon: "icon-hangouts", label: "profile" },
        ]
    },
    {
        id: 'c4', title: "Portfolio", icon: "icon-briefcase", inputs: [
            { id: 'po1', title: "DeviantArt", prefix: "deviantart.com/", icon: "icon-deviantart", label: "DeviantArt Profile URL", code: "https://", type: "url", xcode: "URL" },
            { id: 'po2', title: "Dribbble", prefix: "dribbble.com/", icon: "icon-dribbble", label: "Dribbble Profile URL", code: "https://", type: "url", xcode: "URL" },
            { id: 'po3', title: "Behance", prefix: "behance.net/", icon: "icon-behance", label: "Behance Profile URL", code: "https://", type: "url", xcode: "URL" },
            { id: 'po4', title: "500px", prefix: "500px.com/p/", icon: "icon-500px", label: "500px Profile URL", code: "https://", type: "url", xcode: "URL" },
            { id: 'po6', title: "Pinterest", prefix: "pinterest.com/", icon: "icon-pinterest", label: "Pinterest Profile URL", code: "https://", type: "url", xcode: "URL" },
            { id: 'po7', title: "Github", prefix: "github.com/", icon: "icon-github", label: "Github Profile URL", code: "https://", type: "url", xcode: "URL" },
            { id: 'po8', title: "Gitlab", prefix: "gitlab.com/", icon: "icon-gitlab", label: "Gitlab Profile URL", code: "https://", type: "url", xcode: "URL" },
            { id: 'po9', title: "Bitbucket", prefix: "bitbucket.com/", icon: "icon-bitbucket", label: "Bitbucket Profile URL", code: "https://", type: "url", xcode: "URL" },
            { id: 'po10', title: "SoundCloud", prefix: "soundcloud.com/", icon: "icon-soundcloud", label: "SoundCloud Profile URL", code: "https://", type: "url", xcode: "URL" },
            // { id: 'po5', title: "Flickr", prefix: "https://", icon: "icon-flickr", label: "Flickr URL", code: "https://", type: "url", xcode: "URL" },
        ]
    },
]


