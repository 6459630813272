import { Switch, Route, useLocation } from "react-router-dom"

import { AnimateSharedLayout, AnimatePresence } from "framer-motion"

import { AuthProvider } from "./contexts/AuthContext"
import PrivateRoute from './hoc/PrivateRoute';

import Dashboard from './screens/dashboard/Dashboard'
import Card from './screens/card/Card'
import ActivateAccount from './screens/activate/ActivateAccount'
import ActivatePhoto from './screens/activate/ActivatePhoto'
import ActivateInformation from './screens/activate/ActivateInformation'
import ActivateReview from './screens/activate/ActivateReview'
import ActivateSuccess from './screens/activate/ActivateSuccess'
import EditPhoto from './screens/edit/EditPhoto'
import EditInformation from './screens/edit/EditInformation'
import Login from './screens/Login';

import { ToastContainer, Slide } from 'react-toastify';
import { TourRoot} from "react-rtg";


function App() {
  const location = useLocation()
  return (
    <TourRoot>
      <AnimateSharedLayout>
        <TourRoot>
          <AuthProvider>
            <AnimatePresence exitBeforeEnter >
              <Switch location={location} key={location.pathname}>
                <PrivateRoute path="/:id/activate/photo" component={ActivatePhoto} />
                <PrivateRoute path="/:id/activate/information" component={ActivateInformation} />
                <PrivateRoute path="/:id/activate/review" component={ActivateReview} />
                <PrivateRoute path="/:id/activate/success" component={ActivateSuccess} />
                <Route path="/:id/activate/account" component={ActivateAccount} />
                <Route path="/:id/activate/" component={ActivateAccount} />

                <PrivateRoute path="/:id/edit/information" component={EditInformation} />
                <PrivateRoute path="/:id/edit/photo" component={EditPhoto} />
                <PrivateRoute path="/:id/edit/" component={EditPhoto} />

                <Route path="/login" component={Login} />
                <Route path="/:id" component={Card} />
                <PrivateRoute path="/" component={Dashboard} />
              </Switch>
            </AnimatePresence>
          </AuthProvider>
          </TourRoot>
      </AnimateSharedLayout>

      <ToastContainer
        bodyClassName={() => "text-red-400 text-center font-sans text-sm p-4"}
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        transition={Slide}
      />

    </TourRoot>
  );
}

export default App;
