import { createSlice } from '@reduxjs/toolkit'
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from '../../config/firebase';

export const editSlice = createSlice({
  name: 'edit',
  initialState: {
    error: null,
    isLoading: false
  },
  reducers: {
    editStartLoading: state => {
      state.isLoading = true;
    },
    editSuccess: state => {
      state.isLoading = false
      state.error = null
    },
    editError: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  }
})

export default editSlice.reducer

// Action creators are generated for each case reducer function
export const { editError, editSuccess, editStartLoading } = editSlice.actions


export const editPhoto = (card, uri) => async dispatch => {
  dispatch(editStartLoading())
  try {
    var cardRef = doc(firestore, 'cards', card.id)
    return updateDoc(cardRef, {
      'avatar_url': uri,
    })
      .then(() => {
        dispatch(editSuccess())
      })
      .catch((error) => {
        dispatch(editError(error))
      });
  }
  catch (e) {
    dispatch(editError(e))
  }
}

export const editInformation = (card, dataset) => async dispatch => {
  dispatch(editStartLoading())
  try {
    var cardRef = doc(firestore, 'cards', card.id)
    return updateDoc(cardRef, {
      dataset: dataset
    })
      .then(() => {
        dispatch(editSuccess())
      })
      .catch((error) => {
        dispatch(editError(error))
      });


  }
  catch (e) {
    dispatch(editError(e))
  }
}
