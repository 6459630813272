import React from 'react'

const PageNotFound = () => (
    <div className="absolute top-0 left-0 right-0 h-screen flex items-center justify-center">
        <div className="max-w-sm text-center">
            <div className="w-12 mx-auto opacity-50"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.82 14.82"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M3.07,0A3.07,3.07,0,0,0,0,3.07v8.68a3.07,3.07,0,0,0,3.07,3.07h8.67a3.08,3.08,0,0,0,3.08-3.07V3.07A3.08,3.08,0,0,0,11.74,0Zm0,.51h8.67a2.55,2.55,0,0,1,2.57,2.56v8.68a2.55,2.55,0,0,1-2.57,2.56H3.07A2.55,2.55,0,0,1,.51,11.75V3.07A2.55,2.55,0,0,1,3.07.51Zm-.84.86a.86.86,0,0,0-.61,1.47l1,1-1,1A.87.87,0,1,0,2.84,6.05l1-1,1,1A.87.87,0,1,0,6.05,4.82l-1-1,1-1A.87.87,0,1,0,4.82,1.62l-1,1-1-1A.85.85,0,0,0,2.23,1.37Zm7.15,0a.85.85,0,0,0-.61.25.88.88,0,0,0,0,1.22l1,1-1,1a.89.89,0,0,0,0,1.23.88.88,0,0,0,1.22,0l1-1,1,1a.88.88,0,0,0,1.22,0,.89.89,0,0,0,0-1.23l-1-1,1-1a.88.88,0,0,0,0-1.22.86.86,0,0,0-1.22,0l-1,1-1-1A.84.84,0,0,0,9.38,1.37Zm-4,.5A.36.36,0,0,1,5.68,2a.33.33,0,0,1,0,.5L4.51,3.65a.25.25,0,0,0,0,.36L5.68,5.19a.33.33,0,0,1,0,.5.35.35,0,0,1-.5,0L4,4.51a.25.25,0,0,0-.36,0h0L2.48,5.69a.35.35,0,0,1-.5-.5L3.15,4a.25.25,0,0,0,0-.36L2,2.48a.35.35,0,0,1,.25-.6h0a.35.35,0,0,1,.25.1L3.65,3.15a.25.25,0,0,0,.36,0H4L5.18,2a.36.36,0,0,1,.25-.11Zm7.16,0a.36.36,0,0,1,.25.11.35.35,0,0,1,0,.5L11.67,3.65a.25.25,0,0,0,0,.36l1.17,1.18a.35.35,0,0,1-.5.5L11.17,4.51a.27.27,0,0,0-.37,0h0L9.63,5.69a.35.35,0,0,1-.5-.5L10.3,4a.25.25,0,0,0,0-.36L9.13,2.48a.35.35,0,0,1,.25-.6h0a.35.35,0,0,1,.25.1L10.8,3.15a.25.25,0,0,0,.36,0h0L12.34,2a.36.36,0,0,1,.25-.11ZM7.39,9a4.32,4.32,0,0,0-1.75.36,2.69,2.69,0,0,0-1.78,2.38.27.27,0,0,0,.23.26.26.26,0,0,0,.28-.23s0,0,0,0a2.17,2.17,0,0,1,1.47-1.9,4,4,0,0,1,3.1,0,2.17,2.17,0,0,1,1.5,1.89.26.26,0,0,0,.51,0h0A2.67,2.67,0,0,0,9.14,9.33,4.34,4.34,0,0,0,7.39,9Z" /></g></g></svg></div>
            <p className="p-8 text-xs tracking-wider leading-5">We are sorry but the page you are looking for does not exist, Have been removed or is temporarily unavailable.</p>
        </div>
    </div>
)

export default PageNotFound
