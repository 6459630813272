import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import 'tailwindcss/tailwind.css';
import './styles/icons.css'
import App from './App';
import { BrowserRouter as Router } from "react-router-dom"
import store from './config/store'
import { Provider } from 'react-redux'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <Router>
      <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

