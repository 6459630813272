import React from 'react'
import { motion } from "framer-motion";
import clsx from 'clsx'
import Obfuscate from 'react-obfuscate';

export default function InfoList({ card }) {
    const staggerContainer = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {

                staggerChildren: 0.1,

            }
        },
        exit: {
            opacity: 0,
            transition: {
                staggerChildren: 0.1,

            }
        }
    }
    const staggerItem = {
        hidden: { opacity: 0, y: 5 },
        show: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 5 }
    }
    return (

        <motion.div className="w-full pb-12" initial="hidden" animate="show" exit="exit" variants={staggerContainer} >

            {card.dataset &&
                card.dataset.map((item, index) => (
                    item.link == item.value ?
                        <div key={item.iid + index} className="h-14 mb-2 flex flex-row items-center bg-foreground p-2 shadow-sm rounded-md" variants={staggerItem} transition={{ duration: 0.5 }} >
                            <div className="pl-3 pr-6"><i className={clsx(["font-md text-content", item.icon])} /></div>
                            <div className="flex-1">
                                <label className="text-sm text-content-mute">{item.label}</label>
                                <h2 className="text-sm font-medium text-content overflow-ellipsis overflow-hidden w-52 sm:w-full">{item.prefix + item.value}</h2>
                            </div>
                        </div>
                        :
                        <motion.div key={item.iid + index} className="h-14 mb-2 bg-foreground  shadow-sm rounded-md" variants={staggerItem} transition={{ duration: 0.5 }} >
                            
                            <Obfuscate href={item.link} target="_blank" >
                                <div className="flex flex-row items-center p-2" style={{direction: 'ltr'}} >
                                <div className="pl-3 pr-6"><i className={clsx(["font-md text-content-mute", item.icon])} /></div>
                                <div className="flex-1">
                                    <label className="text-sm text-content-mute">{item.label}</label>
                                    <h2 className="text-sm font-medium text-content overflow-ellipsis overflow-hidden w-52 whitespace-nowrap sm:w-full">{item.prefix + item.value}</h2>
                                </div>
                                </div>
                            </Obfuscate>
                        </motion.div>
                ))
            }
        </motion.div>

    )

}
