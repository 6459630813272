import React, { useCallback } from 'react'
import { Tour, Step } from "react-rtg";
import Cookies from 'universal-cookie';


export default function ActivationTour() {

    const cookies = new Cookies();
    const controls = useCallback(({ prev, next, close, current, length }) => {
        return (
            <div className={"some-class__controls border-t mt-8"}>
                <div className="flex flex-1  pt-2 mt-2">
                    <div>
                        <button onClick={close}
                            className={"btn btn--skip p-2"}>
                            Skip
                        </button>
                    </div>

                    <div className="flex-1 text-right">
                        {
                            (current === length - 1) ?
                                <button onClick={close}
                                    className={"btn btn--next p-2 text-blue-600"}>
                                    Let's Start
                                </button>
                                :
                                <button onClick={next}
                                    className={"btn btn--next p-2 text-blue-600"}>
                                    Next <i className="icon icon-arrow-right mt-4 " />
                                </button>
                        }
                    </div>
                </div>
            </div>
        )
    }, [])

    function handleTourComplete(){
        cookies.set('tour',true)
    }

    return (
        <Tour
            id="activateTour"
            badge={false}
            closeButton={false}
            controls={controls}
            className="text-content-mute"
            onFinish={handleTourComplete}
        >
             <Step placement="center">
                <h2 className=" text-md font-bold">Let's Add Your Information</h2>
                <p className="px-4 mb-8 mt-4 max-w-sm">We would like to walk you through the basic functionality to add your information.</p>

            </Step>

            <Step placement="center" >
                <h2 className=" text-md font-bold">Add Sections</h2>
                <p className="px-4 mb-8 mt-4 capitalize max-w-sm">Add Personal Information, Social Media, Instant Messaging or Portfolio Links. </p>
                <div className="text-center">
                    <img src="/img/gif/add-info.gif" width="240" className="mx-auto" />
                </div>
            </Step>

          
            <Step placement="center" >
                <h2 className=" text-md font-bold">Order Sections</h2>
                <p className="px-4 mb-8 mt-4 capitalize max-w-sm">Drag / Drop Sections To Change The Order.</p>
                <img src="/img/gif/order-info.gif" width="240" className="mx-auto" />

            </Step>

            <Step placement="center" >
                <h2 className=" text-md font-bold">Delete Sections</h2>
                <p className="px-4 mb-8 mt-4 capitalize max-w-sm">Swipe left to remove a section from your card information.</p>
                <img src="/img/gif/delete-info.gif" width="240" className="mx-auto" />
            </Step>

            <Step placement="center" >
                <h2 className=" text-md font-bold">Edit Sections</h2>
                <p className="px-4 mb-8 mt-4 capitalize max-w-sm">Click on the section to edit/update values or label.</p>
                <img src="/img/gif/edit-info.gif" width="240" className="mx-auto" />
            </Step>


        </Tour>
    )
}
