import { configureStore } from '@reduxjs/toolkit'
import cardReducer from '../screens/card/cardSlice'
import dashboardReducer from '../screens/dashboard/dashboardSlice'
import activateReducer from '../screens/activate/activateSlice'
import editReducer from '../screens/edit/editSlice'
export default configureStore({
  reducer: {
      card: cardReducer,
      dashboard: dashboardReducer,
      activate: activateReducer,
      edit: editReducer
  }
})