import { createSlice } from '@reduxjs/toolkit'
import { doc, getDoc } from "firebase/firestore";
import { firestore } from '../../config/firebase';

export const cardSlice = createSlice({
  name: 'card',
  initialState: { data: null, error: null, isLoading: false },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    cardSuccess: (state, action) => {
      state.data = action.payload
      state.isLoading = false
      state.error = null
    },
    cardError: (state, action) => {
      state.data = null
      state.isLoading = false
      state.error = action.payload
    },
  }
})

export default cardSlice.reducer

// Action creators are generated for each case reducer function
export const { cardSuccess, cardError, startLoading } = cardSlice.actions

export const fetchCard = (id) => async dispatch => {
  dispatch(startLoading())
  try {
    const cardRef = doc(firestore, 'cards', id)
    await getDoc(cardRef).then((doc) => {

      if (doc.exists()) {
        dispatch(cardSuccess({ ...doc.data(), id: doc.id }))
      }
      else {
        dispatch(cardError({
          code: "Invalid Card"
        }))
      }
    }).catch((error) => {
      dispatch(cardError(error))
    });
  }
  catch (e) {
    dispatch(cardError(e))
  }
}