import React from 'react'
import Spinner from './Spinner'

const FullPageLoader = () => (
  
    <div className="absolute top-0 left-0 right-0 h-screen flex items-center justify-center">
    <Spinner className="fill-current fill-white" />
    </div>
)

export default FullPageLoader
