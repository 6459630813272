import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import SwipeToDelete from 'react-swipe-to-delete-ios'
import clsx from 'clsx';
import { bottomSheet } from "react-simple-bottom-sheet";

import InfoBottomSheet from './InfoBottomSheet'

const DragHandle = sortableHandle(() => <div className="w-8 text-right "><span className="icon-move-vertical text-content-mute" /></div>);

const SortableItem = SortableElement(({ item, onDelete, onClick, index }) => {

    return (
        <div className="mb-2 step-two" >
            <SwipeToDelete
                onDelete={() => { onDelete(index) }}
                height={56}
                transitionDuration={250}
                deleteWidth={75}
                deleteColor="rgba(252, 58, 48, 0.1)"
                deleteComponent={<i className="icon-trash-2 text-red-500" />}

            >
                <div className="h-14 flex flex-row items-center bg-foreground shadow-sm rounded-md step-four" >
                    <div className="pl-5 pr-2"><i className={clsx(["font-md text-content icon ", item.icon])} /></div>
                    <div className="flex-1 pl-3" onClick={() => { onClick(item) }}>
                        {item.label && <label className="text-sm text-content-mute">{item.label}</label>}
                        <h2 className="text-sm font-medium text-content overflow-ellipsis overflow-hidden w-52 sm:w-full">{item.prefix + item.value}</h2>
                    </div>
                    <div className="pr-4 step-three"><DragHandle /></div>
                </div>
            </SwipeToDelete>
        </div>
    )
}
)

const SortableList = SortableContainer(({ items, onDelete, onItemClick }) => {
    const staggerContainer = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
            }
        },
        exit: {
            opacity: 0,
            transition: {
                staggerChildren: 0.1,
            }
        }
    }

    return (

        <motion.div className="w-full" initial="hidden" animate="show" exit="exit" variants={staggerContainer} >
            {items.map((value, index) => (
                <SortableItem key={`item-${value.label + index}`} index={index} item={value} onDelete={() => { onDelete(index) }} onClick={onItemClick} />
            ))}
        </motion.div>

    );
});

export default function InfoOption({ card, onChange }) {
    const [itemList, setItemList] = useState(card.dataset ? card.dataset : []);
    const [editIndex, setEditIndex] = useState(-1);


    useEffect(() => {
        onChange(itemList)
    }, [itemList]);

    useEffect(() => {
        if (editIndex >= 0) {
            bottomSheet.create({
                content: (
                    <InfoBottomSheet submit={handleOnEditComplete} edit={itemList[editIndex]} />
                ),
            });
        }
    }, [editIndex])


    function handleOnAddComplete(itm) {
        setItemList([...itemList, itm])
        bottomSheet.close()
    }

    function handleOnEditComplete(itm) {
        let newItemList = [...itemList];
        newItemList.splice(editIndex, 1, itm);
        setItemList(newItemList);
        bottomSheet.close()
    }

    function handleDelete(indx) {
        let newItemList = [...itemList]
        newItemList.splice(indx, 1)
        setItemList(newItemList);
    }

    function onSortEnd({ oldIndex, newIndex }) {
        setItemList(arrayMoveImmutable(itemList, oldIndex, newIndex));
    }

    function handleEditItem(item) {
        let index = itemList.findIndex((n) => {
            return (item.iid == n.iid && item.value == n.value);
        })
        setEditIndex(index); // This will trigger bottomsheet on useEffect
    }

    function handleAddBottomSheet() {
        bottomSheet.create({
            content: (
                <InfoBottomSheet submit={handleOnAddComplete} />
            ),
        });
    }


    return (
        <>

            {itemList.length < 1 &&
                <div className="mt-12 mb-12 text-center">
                    <motion.h1 className="text-lg uppercase font-semibold " transition={{ duration: 0.5 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} >Add Information</motion.h1>
                    <motion.p className="text-md  text-content-mute px-8 " transition={{ duration: 0.5 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} >Basic information, Social Profiles, Instant Messaging and more.</motion.p>
                </div>}

            <SortableList useDragHandle items={itemList} onSortEnd={onSortEnd} onDelete={handleDelete} onItemClick={handleEditItem} />

            <motion.div className="w-full mt-3" transition={{ duration: 0.5 }} initial="hidden" animate="visible" exit="exit" variants={{ visible: { opacity: 1, y: 0 }, hidden: { opacity: 0, y: 10 }, exit: { opacity: 0, y: 10 } }} >
                <button type="submit" className="step-one w-full inline-flex justify-center py-3 border border-dashed border-content  text-sm font-light rounded-md text-content hover:text-content focus:text-content uppercase bg-transparent focus:outline-none font-medium " onClick={handleAddBottomSheet}> + Add Section</button>
            </motion.div>
         

        </>
    )
}
