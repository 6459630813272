import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useTour } from "react-rtg";
import Cookies from 'universal-cookie';

import Page from '../../components/Page'
import Spinner from '../../components/Spinner'
import Header from '../../components/Header'
import BusinessCard from '../../components/BusinessCard'
import CardContainer from '../../components/CardContainer'
import { activateInformation } from './activateSlice'
import { fetchCard } from '../card/cardSlice'
import InfoInput from '../../components/InfoInput'
import ActivationTour from '../../components/ActivationTour'

export default function ActivateInformation() {

    const history = useHistory()
    const { id } = useParams();
    const { isLoading, error } = useSelector(state => state.activate);
    const { data } = useSelector(state => state.card);
    const [dataset, setDataset] = useState([])
    const dispatch = useDispatch()

    const [tour] = useTour("activateTour")
    const cookies = new Cookies();

    useEffect(() => {
        if (error && error.code) {
            toast(error.code)
        }
    }, [error])

    useEffect(() => {
        if (data && data.is_active) {
            history.push(`/${id}`)
        }



    }, [data])

    useEffect(() => {
        if(!cookies.get('tour')){
            tour.open()
        }
    }, [tour])

    async function onStepComplete() {
        await dispatch(activateInformation(data, dataset))
        await dispatch(fetchCard(id));
        history.push(`/${id}/activate/review`)
    }

    function handleChange(items) {
        setDataset(items)
    }
    return (
        <>
            <ActivationTour />
            <CardContainer>
                {data &&
                    <Page>
                        <div className="flex flex-col items-center flex-1">

                            <div className=" flex-1  -mt-48 md:-mt-44  pb-20" >
                                <motion.div layoutId="solocard" transition={{ duration: 0.8, ease: "backInOut" }}>
                                    <BusinessCard dataset={data} />
                                </motion.div>
                                <div className="my-4" >
                                    <Header steps={true} />
                                </div>

                                <motion.div className="w-full" layoutId="info-container" transition={{ duration: 0.8, ease: "backInOut" }} >
                                    <InfoInput card={data} onChange={handleChange} />
                                </motion.div>
                            </div>

                            <motion.div

                                transition={{ duration: 0.5, ease: "backInOut" }} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }}
                                className="w-full max-w-lg fixed bottom-0 p-8"
                                style={{ backgroundImage: "linear-gradient(rgba(229, 229, 229, 0), rgba(229, 229, 229, 1) 25%)" }}
                            >
                                <a
                                    onClick={onStepComplete}
                                    className="cursor-pointer w-full inline-flex justify-center py-4 border border-transparent shadow hover:shadow-lg shadow-sm text-sm font-light rounded-sm text-white uppercase bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 " >
                                    {isLoading ? <Spinner className="fill-current fill-white" size="small" /> : 'CONTINUE'}
                                </a>
                            </motion.div>
                        </div>




                    </Page>
                }
            </CardContainer>
        </>
    )
}
