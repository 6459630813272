import React from 'react'
import clsx from 'clsx'

export default function IconButton({ label, icon, onClick, className, color = "content", variant = "outlined" }) {

    return (
        <div className={clsx([className])}>
            <button
                onClick={onClick}
                className={
                    clsx([`cursor-pointer w-14 h-14 inline-flex justify-center items-center text-md font-light rounded-full focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-${color} shadow-md hover:shadow-lg`,
                    (variant == 'contained') ?
                        `bg-${color} hover:bg-${color} text-white text-opacity-80 hover:text-opacity-100`
                        :
                        `border-2 border-solid border-${color} text-${color} uppercase bg-transparent hover:bg-${color} hover:bg-opacity-5`
                    ])}
            >
                <i className={`icon-${icon}`} />
            </button>
            <p className={`w-24 text-sm mt-2 text-${color}`}>{label}</p>
        </div>
    )
}
