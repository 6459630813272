import React, { useRef, useState, useCallback, useEffect } from 'react'
import { motion } from "framer-motion"
import { toast } from 'react-toastify';
import ReactCrop from 'react-image-crop';

import Spinner from './Spinner';

export default function PhotoUpload({ onSuccess, proccessing = false, card }) {
    const [isLoading, setIsLoading] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [cropType, setCropType] = useState('avatar');

    const cropperRef = useRef(null)
    const imgRef = useRef(null);
    const [upImg, setUpImg] = useState(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [touched, setTouched] = useState(false);
    const [initialized, setInitialized] = useState(false)

    const onSelectFile = (e) => {
        setTouched(true);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (imgRef.current) {
                setCrop({ unit: '%', width: 100, aspect: 1, x: 0, y: 0 })
        }

    }, [cropType]);

    useEffect(() => {
       
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const canvCrop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
        canvas.width = canvCrop.width * pixelRatio;
        canvas.height = canvCrop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            canvCrop.x * scaleX,
            canvCrop.y * scaleY,
            canvCrop.width * scaleX,
            canvCrop.height * scaleY,
            0,
            0,
            canvCrop.width,
            canvCrop.height
        );

       
        setCroppedAreaPixels(ctx.canvas.toDataURL())
    }, [completedCrop, upImg]);

    useEffect(() => {
        if (card.avatar_url) {
            setUpImg(card.avatar_url);
            setCrop({
                unit: '%',
                width: 100,
                aspect: 1,
                x: 0,
                y: 0,
            });
        }
    }, [card])
    const onFileDelete = () => {
        setTouched(true)
        setCroppedAreaPixels(null);
        setUpImg(null)
        setCompletedCrop(null);
        setCropType('avatar');
    }

    async function uploadFile() {

        try {
            if (croppedAreaPixels && touched) {
                setIsLoading(true);
                onSuccess(croppedAreaPixels)
            }
            else if(upImg == null){
                // delete image
                onSuccess(false)
            }
            else {
                onSuccess()
            }
        } catch (e) {
            toast(e.message);
            setIsLoading(false);
        }
    }


    return (
        <>

            <motion.h1 className="text-lg uppercase font-semibold " transition={{ duration: 0.5, delay: 0.5 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} >Upload A Picture</motion.h1>
            <motion.p className="text-md  text-content-mute px-8" transition={{ duration: 0.5, delay: 0.45 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }} >Upload your best picture of yourself, this will be displayed when someone saves you to their contacts.</motion.p>

            <motion.div
                transition={{ duration: 0.5, delay: 0.4 }} initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ opacity: 0 }}
                className="flex flex-1 mt-8 w-full flex-col">
                <div className="flex flex-col justify-center  ">

                    {upImg ?
                        <>
                            <div className="hidden">
                                <canvas
                                    ref={previewCanvasRef}
                                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                    style={{
                                        width: Math.round(completedCrop?.width ?? 0),
                                        height: Math.round(completedCrop?.height ?? 0)
                                    }}
                                />
                            </div>


                            <div className="w-40  relative mx-auto ">
                                <div className="w-40 min-h-40 p-2 flex bg-white bg-opacity-20 items-center  relative mx-auto border rounded-md overflow-hidden">
                                    <ReactCrop
                                        ref={cropperRef}
                                        src={upImg}
                                        onImageLoaded={onLoad}
                                        crop={crop}
                                        onChange={(c) => { setCrop(c); }}
                                        onComplete={(c) => { setCompletedCrop(c); setInitialized(true); if(initialized){setTouched(true)}}}
                                        keepSelection={true}
                                        minHeight="40"
                                        minWidth="40"
                                        ruleOfThirds={true}
                                    />
                                </div>

                                <button onClick={onFileDelete} className="absolute -bottom-2 -right-2 border-0 bg-blue-600 w-8 h-8 z-10 rounded-full">
                                    <i className="icon-trash-2 text-white" />
                                </button>
                            </div>
                        </>

                        :
                        <label className="w-32 h-32 mx-auto inline-flex justify-center items-center border border-dashed border-content-mute text-xl font-light rounded-md text-content-mute uppercase bg-white bg-opacity-30 hover:bg-opacity-10 transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ">
                            {isLoading ? <Spinner /> : <i className="text-content-mute icon-user-plus" />}

                            <input ref={imgRef} id="myFileInput" type="file" accept="image/*;capture=camera" className="hidden" onChange={onSelectFile} />
                        </label>

                    }

                </div>



            </motion.div>

            <motion.div
                transition={{ duration: 0.5, ease: "backInOut" }} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }}
                className="w-full fixed bottom-0 left-0">


                <div className="max-w-lg mx-auto  p-8 ">
                    <a
                        onClick={uploadFile}
                        className="cursor-pointer w-full inline-flex justify-center py-4 border border-transparent shadow hover:shadow-lg text-sm font-light rounded-sm text-white uppercase bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 " >
                        {(isLoading || proccessing) ?
                            <Spinner className="fill-current fill-white" size="small" /> :
                            touched ? 'Save & Continue' : 'Skip'}
                    </a>
                </div>

            </motion.div>

        </>

    )
}


