import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { motion } from "framer-motion";

import TextField from './TextField';
import { inputFieldOptions } from '../utils/getInputFieldOptions';


export default function InfoBottomSheet({ edit = null, submit = (i) => { } }) {
    const [currentOption, setCurrentOption] = useState()
    const [selectedInput, setSelectedInput] = useState(null)
    const optionList = inputFieldOptions;


    const newItemForm = useFormik({
        initialValues: {
            label: '',
            value: '',
        },
        onSubmit: (values) => {
            let itm = {
                label: values.label,
                value: values.value,
                icon: selectedInput.icon,
                link: selectedInput.code + values.value,
                oid: currentOption.id,
                iid: selectedInput.id,
                prefix: selectedInput.prefix
            }

            submit(itm);
        },
    });

    useEffect(() => {

        if (edit) {
            let opt = optionList.find((o) => {
                return o.id == edit.oid
            })
            let inp = opt.inputs.find((i) => {
                return i.id == edit.iid
            })
            setCurrentOption(opt);
            setSelectedInput(inp)
            newItemForm.setValues({
                label: edit.label,
                value: edit.value,
            })
        }
    }, [edit])

    function handleSelectInput(inpt) {
        setSelectedInput(inpt);
        let v = '';
        // if (inpt.type == "tel") {
        //     v = card.phone
        // }
        newItemForm.setValues(prevValues => ({
            ...prevValues,
            label: inpt.title,
            value: v
        }));
    }
    return (
        <div className="max-w-lg mx-auto h-96 px-4 ">
            <div className="relative max-w-lg  mx-auto mb-4">
                {currentOption &&
                    <motion.div
                        className="absolute left-6 top-0"
                        transition={{ duration: 0.5 }} initial={{ x: 10, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -10, opacity: 0 }}>
                        <a onClick={() => setCurrentOption(null)} className="p-2 text-xl">
                            <i className="icon-arrow-left" />
                        </a>
                    </motion.div>}
                <h1 className="flex items-center  justify-center uppercase text-content tracking-wider">{currentOption ? currentOption.title : 'Select A Field'}</h1>
            </div>


            {currentOption ?
                <div>
                    <Swiper
                        freeMode={true}
                        slidesPerView="auto"
                        spaceBetween={8}
                        loop={true}
                    >
                        {currentOption.inputs.map((inpt) => {
                            return (
                                <SwiperSlide key={inpt.id} className={clsx(["w-24 h-24 flex-1"])} onClick={() => { handleSelectInput(inpt) }}>
                                    <div className={clsx([" w-20 h-20 border rounded-sm flex flex-col items-center justify-center", (selectedInput && inpt.id == selectedInput.id) && "border-blue-400"])} key={inpt.id}>
                                        <i className={`text-lg mb-1 icon ${inpt.icon} text-blue-500`} />
                                        <p className="text-xs font-medium text-content-mute mt-2">{inpt.title}</p>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>


                    {selectedInput &&
                        <form onSubmit={newItemForm.handleSubmit}>
                            <TextField
                                name="label"
                                label="Label"
                                type="text"
                                onChange={newItemForm.handleChange}
                                onBlur={newItemForm.handleBlur}
                                value={newItemForm.values.label}
                            />
                            <TextField
                                name="value"
                                type={selectedInput.type}
                                label={selectedInput.label}
                                start={selectedInput.prefix}
                                onChange={(ev) => {
                                    if (selectedInput.type == 'url') {
                                        let formatted = ev.target.value.replace('https://', '').replace('http://', '');
                                        if (formatted.substr(formatted.length - 1) == '/') {
                                            formatted = formatted.slice(0, -1)
                                        }
                                        if (selectedInput.id.includes('sm')) {
                                            let v = formatted.split('/');
                                            formatted = v[v.length - 1]
                                        }
                                        newItemForm.setFieldValue('value', formatted)
                                    }
                                    else {
                                        let formatted = ev.target.value.replace(selectedInput.prefix, '');
                                        newItemForm.setFieldValue('value', formatted)
                                    }

                                }
                                }
                                onBlur={newItemForm.handleBlur}
                                value={newItemForm.values.value}
                            />
                        </form>
                    }
                </div>
                :
                <>
                    <ul>
                        {optionList.map((opt) => {
                            return (
                                <li onClick={() => { setCurrentOption(opt); handleSelectInput(opt.inputs[0]) }} className="mb-2 p-4 font-medium" key={opt.id}><i className={`icon ${opt.icon} text-blue-600 mr-4`} /> <span className="font-alt font-normal">{opt.title}</span></li>
                            )
                        })}
                    </ul>
                </>
            }


            {
                currentOption &&
                <div className="max-w-lg mx-auto  mt-6  ">
                    <button
                        disabled={!(newItemForm.values.label && newItemForm.values.value)}
                        type="submit"
                        onClick={() => { newItemForm.handleSubmit() }}
                        className="w-full inline-flex justify-center py-4 border border-transparent shadow-sm text-sm font-light rounded-sm text-white uppercase bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 ">
                        {!edit ? 'Add' : 'Update'}
                    </button>
                </div>


            }

        </div>
    )
}
