import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import FullPageLoader from '../components/FullPageLoader'
import PageNotFound from '../components/PageNotFound'
import { fetchCard } from '../screens/card/cardSlice'

export default function CardContainer({ children }) {

    const dispatch = useDispatch()
    const { data, error } = useSelector(state => state.card);
    const { id } = useParams();

    useEffect(() => {
        if (!data || (data && data.id !== id)) {
            dispatch(fetchCard(id));
        }
    }, [dispatch]);


    if (data && data.id) {
        return (
            children
        )
    }
    else if (error) {
        return (<PageNotFound />)
    }
    
    else {
        return (<FullPageLoader />)
    }
}
