import React, { useEffect } from 'react'
import { motion } from "framer-motion"
import { useHistory, useParams , Redirect} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import Page from '../../components/Page'
import Header from '../../components/Header'
import BusinessCard from '../../components/BusinessCard'
import CardContainer from '../../components/CardContainer'
import { editPhoto } from './editSlice'
import { fetchCard } from '../card/cardSlice'
import PhotoUpload from '../../components/PhotoUpload'

export default function EditPhoto() {

    const history = useHistory()
    const { id } = useParams();
    const { isLoading, error } = useSelector(state => state.edit);
    const { data } = useSelector(state => state.card);
    const dispatch = useDispatch()

    useEffect(() => {
        if (error && error.code) {
            toast(error.code)
        }
    }, [error])

    async function onStepComplete(uri) {
        if (uri === false) {
            await dispatch(editPhoto(data, null))
        }
        else if (uri) {
            await dispatch(editPhoto(data, uri))
        }
        await dispatch(fetchCard(id));
        history.push(`/${id}/edit/information`)
    }

    return (
        <CardContainer>
            {data && data.is_active ?
                <Page >
                    <div className="flex flex-col items-center flex-1">
                        <div className="flex-1  -mt-48 md:-mt-44 " >
                            <motion.div layoutId="solocard" transition={{ duration: 0.8, ease: "backInOut" }}>
                                <BusinessCard dataset={data} />
                            </motion.div>
                            <div className="my-4" >
                                <Header steps={true} />
                            </div>
                            <div className="flex-1 text-center mt-12">
                                <PhotoUpload card={data} onSuccess={onStepComplete} proccessing={isLoading} />
                            </div>
                        </div>
                    </div>
                </Page>
                :
                <Redirect to={`/${id}/activate`} />
            }
        </CardContainer>
    )
}
