import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion"
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';

import BusinessCard from '../../components/BusinessCard'
import Page from '../../components/Page';
import Header from '../../components/Header'
import { useAuth } from "../../contexts/AuthContext"
import { fetchCards } from './dashboardSlice'
import { cardSuccess } from '../card/cardSlice'
import Spinner from '../../components/Spinner';


export default function Dashboard() {

  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const { cards } = useSelector(state => state.dashboard);
  const [currentCard, setCurrentCard] = useState(null)
  const history = useHistory()
  const [jwt, setJwt] = useState(null);
  const [appleLoading, setAppleLoading] = useState(false)
  const [gpayLoading, setGPayLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchCards(currentUser.uid));
  }, [dispatch]);

  useEffect(() => {
    if (cards && cards.data.length > 0) {
      setCurrentCard(cards.data[0])
      dispatch(cardSuccess(cards.data[0]))
    }
  }, [cards]);

  async function onCardClick() {
    history.push(`/${currentCard.id}/`)
  }

  function handleSlideChange(x) {
    setCurrentCard(cards.data[x.activeIndex])
    dispatch(cardSuccess(cards.data[x.activeIndex]))
  }

  async function onButtonClick() {
    await dispatch(cardSuccess(currentCard))
    if (currentCard.is_active) {
      history.push(`/${currentCard.id}/edit`)
    }
    else {
      history.push(`/${currentCard.id}/activate`)
    }
  }


  async function handleGPay() {
    if (!jwt) {
      setGPayLoading(true)
      // Step 1: call our API to create a loyaltyObject
      const result = await fetch('https://us-central1-solo-card.cloudfunctions.net/api/gpay/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          card: currentCard
        }),
      });
      const details = await result.json();

      // Step 2: set JWT based on API response
      setJwt(details.token);
      setGPayLoading(false)


      var win = window.open(`https://pay.google.com/gp/v/save/${details.token}`, '_blank');
      win.focus();

    }
    else {
      var win = window.open(`https://pay.google.com/gp/v/save/${jwt}`, '_blank');
      win.focus();
    }


  }

  async function handleApplePay() {
    setAppleLoading(true);

    fetch('https://us-central1-solo-card.cloudfunctions.net/api/wallet/pass', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        card: currentCard
      }),
    })
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "solocard.pkpass";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again         

        setAppleLoading(false)
      });

  }

  return (
    <Page background={true} >
      <div className="flex flex-col items-center justify-start flex-1">
        <div className="mb-4 w-full -mt-4">
          <Header />
        </div>
        <motion.div className="flex flex-col items-center mb-4 mt-4" transition={{ duration: 0.5 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Link to="/">
            <svg className="fill-current text-content mx-auto mb-4 w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432.06 336"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M413.92,336a17.71,17.71,0,0,1-12.53-5.19L101.25,30.67a17.71,17.71,0,0,1,25-25L396.2,275.54V168.66a17.71,17.71,0,0,1,35.42,0V318.29A17.71,17.71,0,0,1,413.92,336Z" /><path d="M315.19,135.46a17.71,17.71,0,0,1-12.52-5.18l-100.05-100A17.71,17.71,0,0,1,215.15,0h199.2a17.71,17.71,0,0,1,12.58,30.17L327.77,130.22a17.7,17.7,0,0,1-12.54,5.24ZM257.9,35.41l57.24,57.25,56.73-57.25Z" /><path d="M314.63,336H115.42a17.71,17.71,0,0,1-12.58-30.17l99.17-100a17.66,17.66,0,0,1,12.53-5.24h0a17.69,17.69,0,0,1,12.52,5.18l100,100A17.71,17.71,0,0,1,314.63,336ZM157.9,300.58h114l-57.24-57.24Z" /><path d="M17.71,335.56A17.72,17.72,0,0,1,5.07,305.44L121.58,186.81H17.71A17.7,17.7,0,0,1,0,169.11V18.59A17.71,17.71,0,0,1,30.43,6.28L83.11,60.73A17.71,17.71,0,0,1,57.66,85.36l-22.25-23v89H163.79a17.71,17.71,0,0,1,12.64,30.11L30.34,330.26A17.66,17.66,0,0,1,17.71,335.56Z" /></g></g></svg>
          </Link>
          <span className="text-content text-content-mute text-md tracking-widest font-light uppercase my-2" >
            WELCOME
          </span>
        </motion.div>

        <div className="w-full my-8" >
          <div >
            {cards.isLoading ?
              <div className="opacity-50 relative">
                <img src="img/solo-blank.png" />
                <Link to="https://www.solo-card.com" className="absolute left-0 top-0 bottom-0 right-0 flex items-center justify-center text-center cursor-pointer" target="_blank">

                  <div className="">
                    <Spinner className="fill-current" size="small" />
                  </div>

                </Link>
              </div>
              :

              cards.data.length > 0 ?
              <motion.div layoutId="solocard" transition={{ duration: 0.8, ease: "backInOut" }}>
                <Swiper
                  centeredSlides={true}
                  slidesPerView={1}
                  pagination={cards.data.length > 1}
                  spaceBetween={32}
                  loop={false}
                  onSlideChange={(x) => { handleSlideChange(x) }}
                >
                  {cards.data.map((card) => {
                    return (
                      <SwiperSlide key={card.id} >
                        <BusinessCard key={card.id} onClick={onCardClick} dataset={card} />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
                </motion.div>
                :
                <div className="border border-dashed border-content-mute rounded opacity-50 relative hover:opacity-1">
                  <img src="img/solo-blank.png" />
                  <a href="https://www.solo-card.com" className="absolute left-0 top-0 bottom-0 right-0 flex items-center justify-center text-center cursor-pointer" target="_blank">
                    <div className="">
                      <h1 className=" text-md uppercase font-semibold ">No SOLO Cards</h1>
                      <p className="text-xs  text-content-mute px-8 ">
                        Click here to get a new SOLO Card
                      </p>
                    </div>
                  </a>
                </div>


            }
          </div>
        </div>

        {(!cards.isLoading && cards.data.length > 0 ) &&
        <motion.div className="w-full flex justify-around align-center content-center items-center mt-2" transition={{ duration: 0.5 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <div className="w-32 ">

            {(appleLoading) ?
              <div className="p-3"><Spinner className="fill-current fill-white mx-auto" size="small" /></div>
              :
              <button className="w-full" onClick={handleApplePay}><img src="/img/apple-wallet.svg" className="w-full" /></button>
            }
          </div>
          <div className="w-32">
            {(gpayLoading) ?
              <div className="p-3"><Spinner className="fill-current fill-white mx-auto" size="small" /></div>
              :
              <button className="w-full" onClick={handleGPay}><img src="/img/gpay.svg" className="w-full" /></button>
            }

          </div>

        </motion.div>
        }
      </div>

      {currentCard &&
        <motion.div className="w-full max-w-lg fixed bottom-0 left-center p-8 -ml-8"
          transition={{ duration: 0.5, ease: "backInOut" }} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }}>
          <a
            onClick={onButtonClick}
            className="cursor-pointer w-full inline-flex justify-center py-4 border border-transparent shadow hover:shadow-lg  text-sm font-light rounded-sm text-white uppercase bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 " >
            {currentCard.is_active ? 'EDIT' : 'Activate My Card'}
          </a>
        </motion.div>
      }

    </Page>
  )

}
